/* CSS to disable touch actions */
html,
body {
  touch-action: none;
  -ms-touch-action: none;
}

/* custom css for react-tags */

.react-tagsinput {
  height: 50px;
  background: #151117;
  border: 0.5px solid #494552;
  border-radius: "10px";
  padding: 10px;
  overflow-y: scroll;
}

.react-tagsinput-tag {
  background: #3780c2;
  border: none;
  color: #fff;
  word-wrap: break-word;
  min-width: auto;
}

.react-tagsinput-input {
  color: #fff;
  width: 100%;
}

.addToplayground > .chakra-button__icon {
  margin: 0px !important;
}

.slick-prev,
.slick-next {
  width: 40px !important;
  height: 40px !important;
  z-index: 9999;
}

.slick-prev:before,
.slick-next:before {
  opacity: 1 !important;
  font-size: 30px !important;
}

.assignMember > .chakra-checkbox__label {
  width: 100%;
}

.sidebar-trigger {
  display: none!important;
}
.excileModal{
  padding: 0px !important;
}